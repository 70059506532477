// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { EnvironmentEnum } from '@sites/util-environment';

export const environment = {
  name: EnvironmentEnum.Local,
  production: false,
  // Replaced by actual value during build
  release: 'ab0a1896a42a9ea81842c983f533a37d3bcf9655',

  dashboardUrl: 'https://localhost:4202',

  apiUrl: 'https://api.localhost',

  sentryDsn:
    'https://6504ab8504ec4403bbb00bfca4943b4c@o98546.ingest.sentry.io/6111916',
  sentrySampleRate: 0,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
